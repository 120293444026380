import React, { useContext, useEffect, useState } from "react";
import { createRef } from "react";
import { AppContext } from "../../common/appContext.jsx";
import { TextInput } from "../../components/textInput/index.jsx";


export const ContactForm = () => {
  const formRef = createRef()
  const { globalStrings } = useContext(AppContext)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [isFormValid, setIsFormValid] = useState(false)
  const [succesMessageVisible, setSuccessMessageVisible] = useState(false)

  const resetForm = () => {
    formRef?.current?.reset()
    setIsFormValid(false)
  }

  const handleSubmit = () => {
    if (isFormValid) {
      // Send Email
      const form = new FormData();
      form.append("name", name);
      form.append("email", email);
      form.append("phone", phone);
      form.append("message", message);

      const options = {
        method: 'post',
        body: form,
      };

      console.log('opcion', form, options);
      // options.body = form;

      fetch('https://coffeeweb.studio/api/contact.php', options)
        .then(response => response.json())
        .then(response => {
          console.log(response)
          setSuccessMessageVisible(true)
          resetForm()
        })
        .catch(err => console.error(err));
    }
    return false;
  }

  useEffect(() => {
    setIsFormValid(name.length && email.length && phone.length && message.length)
  }, [name, email, phone, message])

  return (
    <form action="#" method="POST" className="grid grid-cols-1 gap-y-6" ref={formRef}>
      <TextInput label={globalStrings?.YourName} onChange={setName} />
      <TextInput label={globalStrings?.Email} onChange={setEmail} />
      <TextInput label={globalStrings?.PhoneNumber} onChange={setPhone} />
      <TextInput label={globalStrings?.Message} type="textarea" onChange={setMessage} />
      <div>
        <button
          type="button"
          className="disabled:bg-gray-300 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-3 px-6 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          disabled={!isFormValid}
          onClick={handleSubmit}
        >
          {globalStrings?.Submit}
        </button>
      </div>
      {succesMessageVisible &&
        <div className="bg-green-300 px-3 py-4 border-r">
          <span>
            {globalStrings?.ContactSentSuccess}
          </span>
          <span className="float-right" onClick={() => setSuccessMessageVisible(false)}>X</span>
        </div>
      }
    </form>
  )
}
