import { gql } from "@apollo/client"

export const GET_SITE_INFO = gql`
query getSiteInformation {
  _site {
    locales
    globalSeo {
      siteName
    }
  }
}
`
export default GET_SITE_INFO
