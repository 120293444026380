import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


export const ImageGallery = ({ images }) => {
  console.log('gallery', images);
  if (!Array.isArray(images)) {
    return null
  }

  if (images.length === 1) {
    const singleImage = images[0]
    return <img src={singleImage.url} className="w-auto rounded-md" alt={singleImage.alt} />
  }

  return (
    <Carousel>
      {images.map((image, k) =>
        <div key={k}>
          <img src={image.url} alt={image.alt || image.title} />
          {(image.title || image.alt) &&
            <p className="legend">{image.title || image.alt || ''}</p>
          }
        </div>
      )}
    </Carousel>
  )

}
export default ImageGallery
