import { useQuery } from '@apollo/client'
import React from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { Outlet } from "react-router-dom"
import { AppContext } from '../../common/appContext.jsx'
import Loading from '../../components/loading/index.jsx'
import GET_SITE_INFO from '../../queries/site.js'
import PublicFooter from './PublicFooter.jsx'
import PublicHeader from './PublicHeader.jsx'


export const PublicLayout = () => {
    const { loading, error, data } = useQuery(GET_SITE_INFO)
    const { setLanguageList } = useContext(AppContext)

    useEffect(() => {
        if (data?._site?.locales && Array.isArray(data?._site?.locales)) {
            setLanguageList(data._site?.locales)
        }
    }, [data,setLanguageList])

    if (loading) return <Loading />
    if (error) return <p>Error loading.</p>;

    return (
        <>
            <PublicHeader />
            <main>
                <Outlet />
            </main>
            <PublicFooter site={data._site} />
        </>
    )
}

export default PublicLayout
