import React, { useContext } from "react";
import { AppContext } from "../../common/appContext.jsx";
import { ContactForm } from "./ContactForm.jsx";
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'

export const ContactSection = () => {
  const { globalStrings } = useContext(AppContext)

  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="mx-auto max-w-lg">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{globalStrings?.GetInTouch}</h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              {globalStrings?.ContactLegend}
            </p>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Oficina</dt>
                <dd>
                  <p>{globalStrings?.ContactPostalAddress}</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <span className="ml-3"><a href="tel:+5219981669012">+52 1 998 166 9012</a></span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <EnvelopeIcon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <span className="ml-3"><a href="mailto:info@allconnectionsconcierge.com">info@allconnectionsconcierge.com</a></span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="mx-auto max-w-lg lg:max-w-none">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  )

}
