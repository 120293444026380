import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/App.js';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module'


const TOKEN = 'cbafdc5bd685dfa87361f75c74bc2c'

// Google Tag Manager
const tagManagerArgs = {
  gtmId: 'GTM-WMFXPSQ'
}
// Enable GTM only on live site
if (window.location.hostname === 'allconnectionsconcierge.com') {
  TagManager.initialize(tagManagerArgs)
}


const client = new ApolloClient({
  uri: 'https://graphql.datocms.com/',
  cache: new InMemoryCache(),
  headers: {
    authorization: `Bearer ${TOKEN}`
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

// const client = ...

// client
//   .query({ query: PageTransportationQuery, })
//   .then((result) => console.log(result));

root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./service-worker.js');
}
