import React, { useEffect } from 'react';
import { ContactSection } from './ContactSection.jsx';

export const ContactPage = () => {
    useEffect(() => {
        document.title = 'Contact | All Connections Concierge'
    }, [])

    return (
        <section>
            <ContactSection />
        </section>
    )
}

export default ContactPage
