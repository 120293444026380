import { useContext } from "react"
import { Outlet } from "react-router-dom"
import { AppContext } from "../../common/appContext.jsx"
import style from './ServiceLayout.module.scss';

export const ServiceLayout = () => {
  const { serviceBanner } = useContext(AppContext)

  return <>
    <div className={`${style['service-header-bg']} h-96`} style={{
      backgroundImage: serviceBanner ? `url(${serviceBanner || ''})` : null,
    }}></div>

    <div className="max-w-7xl mx-auto text-gray-600 font-light p-6">
      <Outlet />
    </div>

    <div className="md:grid-cols-2 md:grid-cols-3 md:grid-cols-4 md:grid-cols-5 md:grid-cols-6"></div>
  </>
}

export default ServiceLayout
